import * as React from 'react';
import { Link } from 'gatsby';
import Container from '../components/shared/Container';

// markup
const NotFoundPage = () => (
  <Container>
    <main>
      <title>Not found</title>
      <h1>Page not found</h1>
      <p>
        Sorry, we couldn’t find what you were looking for <span role="img" aria-label="Pensive emoji">😔</span>
        <br />
        {process.env.NODE_ENV === 'development' ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">Go home</Link>.
      </p>
    </main>
  </Container>
);

export default NotFoundPage;
